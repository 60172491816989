const awsconfig = {
    Auth: {
        region: "us-east-2",
        userPoolId: "us-east-2_3gAo49uSZ",
        userPoolWebClientId: "7v8kgkdc8et24sleb24dv7j4ne",
    },
    API: {
        endpoints: [
            {
                name: "network-state-api",
                endpoint: "https://1vq6wdcjk4.execute-api.us-east-2.amazonaws.com/prod/"
            }
        ]
    }
};

export default awsconfig;
